@import url('https://fonts.googleapis.com/css?family=Hind&display=swap');

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

$bootstrap-dark: #343a40;
$primary-color: #15bfa8;
$secondary-color: #155fbf;

$bootstrap-responsive-width: 770px;
$bootstrap-xs-breakdown: 575px;
$bootstrap-md-breakdown: 575px;
$res0: 575px;
$bootstrap-lg-breakdown: 991px;

$preloaderInnerColor: $primary-color;
$preloaderOuterColor: #87b6e0;// $secondCompanycolor;

$sidenav-width: 240px;
$max-page-width: 1024px;

//@import "rafala";
//@import "menupro-theme";
//@import "iqspace";
//@import "woolsafe";
//@import "stay";
//@import "quartarone-theme";
@import "uderly";

@import "./framework";

@import "dashboard";
@import "checkmark";
@import "sidenav";
@import "file-uploader";
@import "shop-order";

*, body {
    // font-family: Hind;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    background-color: transparent;
}

h1 {
    // font-size: 20pt;
    // font-family: "Hind";
    font-weight: lighter;
    cursor: default;
}

.brand-logo img {
    height: 42px;
    margin-top: 6px;
}

.header-mobile img { // Mobile logo
    height: 42px;
    margin-top: 2px;
}

.login-logo {
    display: flex;
    justify-content: center;
    justify-items: center;
}

@media (min-width: 992px) {
    .footer-fixed .footer {
        height: 42px;
    }
}

.side-avatar {
    width: 100px;
    height: 100px;
}

.container.main {
    max-width: 100%;
}

.btn.btn-outline-info {
    color: $primary-color;
    border-color: $primary-color;
}

// Scrollbars

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

// Track
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

// Handle
::-webkit-scrollbar-thumb {
    background: #777;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
    background: #343a40;
}

#kt_quick_user { 
    .material-icons {
        width: 1.5em;
        font-size: 1.1rem;
    }
}

.company-color {
    color: $primary-color !important;
}

.admin-menus-color {
    color: #cc0000 !important;
}

.rounded-btn {
    border-radius: 50%;
}

.brand-name {
    color: #eeeeee;
    margin-top: 2px;
    cursor: default;
}

.module > .animated-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: $sidenav-width/2;
    transform: translate(-50%,-50%);
}

@media only screen and (max-width: $bootstrap-lg-breakdown) {
    .module > .animated-preloader {
        margin-left: 0;
    }
}

.module h1 {
    margin-top: 10px;
    color: $primary-color;
}

.module h2 {
    clear: both;
    margin-top: 10px;
    font-size: 15pt;
    color: darken($primary-color, 0.2);
}

.module h3 {
    clear: both;
    margin-top: 10px;
    font-size: 12pt;
    font-weight: 500;
    color: $secondary-color;
}

.item-container {
    max-width: $max-page-width;

    > form {
        width: 100%;
        float: left;
    }
}

.item-container.full-width {
    max-width: 100%;
}

@media only screen and (min-width: $bootstrap-md-breakdown) {
    .item-container {
        padding-bottom: 90px;
    }
}

.item-progress-bar {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    margin-left: -25px;
    background-color: #fff;

    label {
        // margin-left: 140px;
        margin-left: 10px;
        margin-bottom: 9px;
    }

    .MuiLinearProgress-root {
        height: 7px;
    }
}

.form-footer {
    width: 100%;
    pointer-events: none;
}

.form-footer button {
    pointer-events: auto;
}

@media only screen and (max-width: $bootstrap-md-breakdown) {
    .form-footer {
        padding-bottom: 15px;
    }
}

@media only screen and (min-width: $bootstrap-md-breakdown) {
    .form-footer {
        padding-top: 10px;
    }
}

.your-id-is-label, .item-id-is-label {
    float: left;
    margin-top: 8px;
}

.your-id-is-control, .item-id-is-control {
    border: 0;
    background: transparent !important;
    width: 100px;
    float: left;
}

.toggle-button {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin-top: 0;
    padding: 0;
}

.toggle-button.toggled {
    background-color: #24b9a1;
    color: white;
}

.filters-form .toggle-button {
    margin-bottom: 10px;
}

.form-group .toggle-button {
    margin-top: 3px;
}

label {
    white-space: nowrap;
}

// Home

.myaccount.widget {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.myaccount.widget .myaccount-link:hover {
    color: $primary-color;
    cursor: pointer;
}

.myaccount.widget p {
    clear: both;
    margin-top: 15px;
}

.myaccount.widget .form-image {
    margin-left: 50%;
    float: left;
    transform: translateX(-50%);
    margin-bottom: 15px;
}

.module-header {
    width: 100%;
    float: left;
}

.module-header h1 {
    float: left;
}

.module-header h2 {
    float: left;
    font-size: 15pt;
    color: #333;
}

.module-header {
    .form-insert, .form-close {
        float: right;
        color: white;
        width: 38px;
        padding-left: 10px;
    }

    .form-close {
        padding-left: 0px;
        width: 40px;
    }
}

.module-header .form-close {
    color: #ffffff;
    background-color: #F64E60;
}

.module-header .form-close:hover {
    background-color: #cc0000;
}

.form-create {
    float: right;
    color: white !important;
}

.form-delete {
    float: left;
    // color: white !important;
    // background-color: #f64e60 !important;
}

// .module-header .form-delete:hover {
//     background-color: #cc0000;
// }

.modal-footer .danger-button {
    margin-left: 1em;
}

.modal-footer.busy {
    opacity: 0.5;
    pointer-events: none;
}

.fullscreen-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    // margin-left: -($sidenav-width+18);
    overflow: hidden;
    background-color: rgba(255,255,255,0.5);
}

.fullscreen-overlay .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: ($sidenav-width+18)/2;
    transform: translate(-50%, -50%);
}

.fullscreen-overlay .centered .animated-preloader {
    transform: none;
}

@media only screen and (max-width: $bootstrap-md-breakdown) {
    .fullscreen-overlay {
        margin-left: 0;
        left: 0;
    }

    .fullscreen-overlay .centered {
        margin-left: 0;
    }
}

.published-button {
    display: block;
    // min-width: 50px;
    margin-left: auto;
    margin-right: auto;
}

.category-dot {
    float: left;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: 1px;
    margin-right: 6px;
}

.category-form .form-image {
    background-color: #343a40;
}

.category-form .form-image .placeholder {
    color: #fff;
}

.pro-slider {
    height: 37px;
    padding: 8px;
}

.pro-slider * {
    float: left;
}

.pro-slider p {
    margin-left: 8px;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker-wrapper input {
    width: 100%;
    padding: 7px 10px 4px 10px;
    border: 1pt solid #ced4da;
    border-radius: 3pt;
}

.item-icons {
    .fa-image {
        margin-left: -21px;
        margin-right: 5px;
    }

    .fas, .far {
        margin-left: 5px;
    }
}

td.item-icons {
    white-space: nowrap;
    display: flex;
    justify-content: end;
    align-items: center;
}

.asset-items td {
    padding: 15px 0 10px 0 !important;
}

.asset-items td .btn {
    padding: 0 0 0 0 !important;
}

td.flex-justify-center {
    display: flex;
    justify-content: center;
}

.asset-viewer.widget :no-button {
    margin-top: 10px;
}

// .games td button {
//     padding: 0;
// }

@media only screen and (min-width: $bootstrap-md-breakdown) {
    .reset-filters-button {
        padding-top: 0;
        padding-bottom: 9px;
    }
}

.react-responsive-modal-overlay {
    z-index: 1031 !important; // The Navbar is 1030
}

.modal-overlay {
    position: absolute;
    background: rgba(255,255,255,0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-overlay .animated-preloader {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.modal-overlay .centered {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

td.action-cell {
    max-width: 80px;
    // display: flex;
    // justify-content: center;
}

td.action-cell button {
    float: right;
}

.image-title {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
}

.submit-box {
    text-align: center;
}

.submit-box button {
    color: white;
}

// .error-row {
//     width: 100%;
//     margin-top: 1em;
// }

.facebook-button button {
    background-color: #4267B2;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    min-width: 210px;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    color: white;
    box-shadow: none;
    border: 0;

    .fas, .far, .fab {
        color: white;
    }
}

.subheader {
    .item-id {
        margin-right: 0.5em;
        color: #b5b5cf;
    }
}